.contact-us{
    width: 85%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 6rem;
}
.contact-us .left{
    width: 48%;    
}
.contact-us .left img{
    width: 100%;   
}
.contact-us .right{
    width: 45%;    
}
.contact-name{
    display: flex;
    justify-content: space-between;
}
.contact-name input{
    width: 47%;
}
.contact-us .right h1{
    color: #F79B39;
    font-size: 32px;   
    margin-bottom: 0.5rem;
}
.contact-us .right p{
    font-size: 14px;
}
.contact-us input{
    padding: 18px;
    border: 2px solid #F79B39;
    border-radius: 10px;
    margin-bottom: 1.5rem;
    outline: none;
}
.contact-us textarea{
    margin-top: 0;
    margin-bottom: 2rem;
}
.contact-form .email{
    width: 100%;
}
.contact-links{
    display: flex;
    justify-content: space-between;
    width: 15%;
    margin-top: 2rem;
}
.contact-links .icon{
    font-size: 30px;
    color: #66276d;
}
.icon2{
    font-size: 25px;
    color: #66276d;
    transition: 0.5s ease-in-out;
}
.icon:hover, .icon2:hover{
    color: #F79B39;
}
.tel{
    display: none;
}


@media screen and (max-width:1000px) {
    .contact-us{
        flex-direction: column-reverse;
    }
    .contact-us .right{
        width: 95%;
        margin: auto;
    }
    .contact-img{
        display: none;
    }
    .contact-us .left{
        width: 95%;
        margin: auto;
    } 
    .contact-links{
        flex-direction: column;
        gap: 20px;
        width: 100%;
        margin-top: 4rem;
    }
    .tel{
        display: block;
        color: #66276d;
        transition: 0.5s ease-in-out;
        font-weight: 600;
    }
    .tel:hover{
        color: #F79B39;
    }
    .tel-link{
        display: flex;
        text-decoration: none;
        align-items: center;
        gap: 10px;
    }

}