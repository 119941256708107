.ver2{
    margin-top: 1rem;
}
.ver-input{
    width: 100%;
    padding: 20px;
    font-size: 1.2rem;
    outline: none;
    border: 2px solid #66276D;
    font-weight: 600;
    letter-spacing: 1rem;
}
.radio-group{
    display: flex;
    justify-content: left;
    align-items: baseline;
}
.radio-group .checkbox{
    margin-right: 2rem;
}
.modal5-form{
    width: 90%;
    margin: auto;
    margin-top: 3rem;
}
.form-btns5{
    margin-top: 2rem;
}
.modal8{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form-btns8{
    width: 100%;
    display: flex;
    justify-content: center;
}


@media screen and (max-width:1000px) {
    .modal5-form{
        width: 100%;
        margin: auto;
        margin-top: 1rem;
    }
    .radio-group .checkbox{
        margin-right: 0;
    }
    .form-btns5{
        margin-top: 1rem;
        /* margin-bottom: -1rem; */
    }
    .form-btns6{
        margin-bottom: -1rem;
    }
    
}