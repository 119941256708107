.bank{
    color: #fff;
    margin-top: 2rem;
}
.account-box{
    display: flex;
    justify-content: space-between
}
.account{
    width: 55%;
}
.account2{
    width: 40%;
}
.bank-name{
    padding: 18px;
    border-radius: 10px;
}
.more-info{
    align-items: center;
}
.hospital-logo{
    height: 55px;
    border-radius: 10px;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    width: 150px;
    font-size: 16px;
}
/* .file{ */
    /* visibility: hidden; */
    /* position: absolute; */
    /* top: 0.7rem; */
    /* left: 2rem; */
    /* z-index: 2; */
    /* display: none; */
/* } */
/* .hospital-logo span{
    position: absolute;
    top: 0.5rem;
    left: 2.5rem;
} */
/* #imageName{
    color: black;
}  */
.social{
    width: 30%;
}
.signup-btn-cont{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 2.1rem;
}
.prev{
    font-weight: 600;
    color: #66276D;
    cursor: pointer;
}


@media screen and (max-width:1000px) {
    .account-box{
        gap: 1rem;
    }
    .account{
        width: 50%;
    }
    .account2{
        width: 50%;
    }
    .bank-name{
        padding: 18px;
        border-radius: 10px;
        width: 50%;
    }
    .more-info{
        flex-direction: column;
        align-items: flex-start;
    }
    .more-info input{
        width: 100%;
    }
    .hospital-logo{
        margin-top: -0.8rem;
    }
    .file{
        left: 0;
    }
    .hospital-logo span{
        left: 2rem;
    }
    .social{
        width: 50%;
    }
    .account-box2{
        gap: 0.5rem;
    }
    

    
    
    
}

