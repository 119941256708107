.dashboard{
    width: 100%;
    display: flex;
}
.dashboard .left{
    width: 15%;
    background-color: #F79B39;
    position: fixed;
    height: 100vh;
}
.dashboard-links{
    width: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}
.dashboard-link{
    text-decoration: none;
    margin-bottom: 3rem;
    font-weight: 600;
    color: #fff;
}
.active-link{
    color: #222;
}
.dashboard-link:hover{
    text-decoration: underline;
}
.dashboard .right{
    width: 70%;
    margin: auto;
    margin-bottom: 10rem;
    margin-left: 22%;
}
.dashboard .right .h3{
    color: #F79B39;
    padding-top: 2rem;
}
.my-patients{
    margin-top: 2rem;
    display: flex;
    flex-flow: row wrap;
}
.my-patients .box{
    width: 40%;
    margin-right: 5rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
}
.card2{
    width: 100%;
    margin: auto;
}
.box-group{
    width: 90%;
    margin: auto;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}
.dashboard-btn{
    padding: 10px 20px;
}


@media screen and  (max-width: 1000px) {
    .dashboard .left1{
        display: none;
    }
    .dashboard .right{
        width: 100%;
        margin-left: 0;
        margin-bottom: 2rem;
    }
    .dashboard .right h3{
        margin-left: 2rem;
    }
    .my-patients{
        width: 100%;
        margin-top: 1rem;
        flex-direction: column;
    }
    .my-patients .box{
        width: 100%;
        margin-right: 0;
        display: flex;
        flex-direction: column;
    }
    .box-group{
        margin-bottom: 3rem;
        gap: 1rem;
        width: fit-content;
    }
    .card2{
        width: 95%;
        margin: auto;
    }   
    
    
    
}
















