.input-container{
    width: 45%;
    display: flex;
    justify-content: center;
    margin: 3rem auto;
    position: relative;
}
.search-bar{
    width: 100%;
    padding: 20px;
    outline: none;
    font-size: 1rem;
    background: #F1F1F1;
    border: 2px solid #F79B39;
    border-radius: 10px;
}
.search-bar::placeholder{
    font-weight: 700;
    font-size: 1rem;
    color: #C4C4C4;
}
.submit-btn{
    background-image: url(../../../assets/images/search.png);
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: 105px 13px;
    background-color: #66276D;
    border: none;
    outline: none;
    padding: 10px 70px 10px 50px;
    border-radius: 20px;
    position: absolute;
    color: #fff;
    font-size: 1rem;
    bottom: 0.8rem;
    right: 0.5rem;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}
.submit-btn:hover{
    background-color: #a138c7b5;
}



/* PRODUCT DETAILS PAGE */
.search-right{
    width: 80%;
}


@media screen and (max-width:1000px){
    .input-container{
        width: 85%;
    }
    .search-bar{
        width: 100%;
        padding: 20px 10px;
    }
    .search-bar::placeholder{
        font-size: 0.9rem;
    }
    .submit-btn{
        background-size: 15px;
        background-position: 10px 11px;
        padding: 10px 10px 10px 30px;
        border-radius: 20px;
        bottom: 0.8rem;
        font-size: 0.9rem;
    }
    .search-right{
       display: none;
    }
}