.donate{
    width: 100%;
    height: 20vh;
    /* background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url(../../../assets/images/donate.png); */
}
.donate-content{
    padding-top: 5rem;
    margin: auto;
    margin-top: 0;
}
.large2{
    color: #F79B39 !important;
    text-align: center !important;
}
.small2{
    color: #000 !important;
}
.donate-boxes{
    display: flex;
    width: 90%;
    margin: auto;
    padding: 2rem 0;
}
.donate-box{
    width: 30%;
    margin: 2rem;
    border-radius: 20px;
    /* background: linear-gradient(113.22deg, rgba(255, 255, 255, 0.4) 1.68%, rgba(255, 255, 255, 0) 56.53%); */
    background-color: #FCDEBD;
    /* box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25); */
    /* backdrop-filter: blur(30px); */
    padding: 30px;
    text-align: center;
    position: relative;
}
.donate-box h3, p{
    margin-bottom: 1.5rem;
}
.donate-box p{
    line-height: 1.5;
}
.donate-btn{
    padding: 10px 40px;
    margin: auto;
}
.donate-faq{
    width: 85% !important;
}



@media screen and (max-width:1000px) {
    /* .donate{
        background-image: url(../../../assets/images/donate2.png);
    } */
    .donate-content{
        padding-top: 4rem;
    }
    .donate-boxes{
        width: 100%;
        flex-direction: column;
    }
    .donate-box{
        width: 70%;
        margin: 1.5rem auto;
    }
    .donate-box p{
        text-align: center;
    }
    
}