@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
*{
    font-family: 'Lato', sans-serif;
    margin: 0;
    padding: 0;
    /* overflow-x: hidden; */
}
input{
    box-sizing: border-box;
}
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-thumb {
    background:  #66276D;
    border-radius: 10px;
}
::-webkit-scrollbar-track {
    background: #f8f8f8;
}

