.card{
    width: 26%;
    margin: 1.5rem;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    padding: 20px 0;
}
.card-content{
    width: 87%;
    margin: auto;
}
.card-content p{
    margin-bottom: 0.5rem;
}
/* .img-container{
    text-align: center;
} */
.img-container .img{
    border-radius: 5px;
    width: 100%;
}
.target-container, .patient-info{
    margin-top: 1rem;
    cursor: pointer;
}
.hospital, .days-left{
    margin-top: 0.5rem;
}
.content{
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}
.content2{
    width: 95%;
    margin: auto;
}
.target-container{
    width: 100%;
    background-color: #F79B39;
    border-radius: 5px;
    color: #fff;
    padding: 15px;
    box-sizing: border-box;
}
.bold{
    font-weight: 600;
    font-size: 1rem;
}
.circle{
    margin-left: 0.5rem;
}
.small{
    font-size: 14px;
}


@media screen and (max-width:1000px){
    .card{
        width: 95%;
        margin: 1.5rem auto;
    }
    
}