.card-details{
    width: 90%;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    padding: 2rem 0;
    margin: 3rem 0 3rem 3rem;
}
.details-content{
    width: 90%;
    margin: auto;
}
.more{
    margin: 1rem 0;
    text-align: right;
}
.view{
    cursor: pointer;
    color: #66276D;
}
.view:hover{
    color: #a138c7b5;
}
.target-container2{
    width: 100%;
    background-color: #F79B39;
    border-radius: 5px;
    color: #fff;
    padding: 5px 0px;
}
.content3{
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}
.content3 p, .content4 p{
    line-height: 1.5;
}
.description{
    margin: 1rem 0;
}
.content4{
    width: 95%;
    margin: auto;
}
.hospitals{
    margin-top: 0.5rem;
}
.email-address, .address{
    margin-top: 0.5rem;
}
.add{
    margin-bottom: 0.5rem;
}
.content5{
    display: flex;
    width: 95%;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    color: #222;
    cursor: pointer;
}
.card-footer{
    margin-top: 1rem;
}
.contributors, .share{
    display: flex;
    align-items: center;
    width: fit-content;
}
.content5 p{
    font-size: 14px !important;
    margin-top: 0.3rem;
}
.icon{
    margin-right: 0.3rem;
    color: #66276D;
}
.small2{
    margin-top: 0;
    margin-bottom: 0;
}
.bold2{
    margin-bottom: 0.5rem;
}
.days-left{
    margin-top: 1rem;
}
.carousel-status {
    display: none;
}

.thumbs-wrapper {
    display: none;
}
.carousel .control-dots {
    position: relative !important;
}
.carousel{
    display: flex;
    flex-direction: column-reverse;
}
.card-footer-btn{
    width: fit-content;
}
.share-link{
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
}
.icon1{
    font-size: 20px;
    margin: 0.5rem;
    color: #66276d !important;
}
.hosp{
    color: #66276D;
    text-align: center;
}
.small3{
    color: #fff !important;
    margin-bottom: 0.5rem;
}





@media screen and (max-width:1000px) {
    .card-details{
        margin: 3rem auto;
        padding: 1rem 0;
    }
    .details-content{
        width: 93%;
    }
    .card-footer-btn{
        padding: 10px;
    }
    .content5{
        width: 100%;
    }
    .img-modal{
        width: 80% !important;
    }
    .patient-modal{
        max-width: 250px;
        max-height: 250px;
        overflow: hidden;
        margin-bottom: 1rem;
    }
    .content5{
        align-items: center;
    }
    .pinfo{
        /* width: 95%;
        margin: auto;
        display: flex;
        justify-content: space-between; */
        flex-direction: column;

    }
    .contributors:active .content5, 
    .share:active .content5, 
    .card-footer-btn:active .content5{
        outline: none !important;
        background: none;
        -webkit-tap-highlight-color: transparent;
    }
    
}


