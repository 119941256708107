header {
  width: 100%;
  height: 600px;
  background: url(../../../assets/images/pic2.jpg) no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
header::before {
  background-color: rgba(0, 0, 0, 0.63);
  content: "";
  height: 600px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.landing-page {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 7rem;
}
.intro {
  width: 49%;
  margin: auto;
  text-align: center;
  z-index: 10;
  color: #fff;
}
.intro .large {
  margin-bottom: 1.5rem;
  font-size: 40px;
  z-index: 10;
}
.intro .small {
  width: 80%;
  margin: 0 auto;
  font-size: 1.25rem;
  line-height: 1.5;
  z-index: 10;
}
.btn-container {
  margin-top: 4rem;
  z-index: 10;
}

.save {
  font-size: 1.25rem;
  margin: 2rem 0 0 7.5%;
  color: #222;
}
.section {
  width: 100%;
  height: auto;
  background-color: #fcdebd;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}
.card-section {
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  margin-left: 6rem;
}
.view-more {
  padding: 0 0 5rem 0;
  background-color: #fcdebd;
}
.view-more a {
  text-decoration: none;
  float: right;
  margin-right: 6rem;
  font-size: 1rem;
  color: #66276d;
  font-weight: 600;
}
.how-box {
  background: none !important;
  box-shadow: none !important;
  backdrop-filter: none !important;
  position: relative;
}
.how-box h3 {
  margin-top: 0.5rem;
}

.why-gsm {
  margin-top: 5rem;
  margin-bottom: -1rem;
  color: #66276d;
}

@media screen and (max-width: 1000px) {
  header, header::before{
    height: 400px;
  }
  .landing-page {
    padding-bottom: 0;
  }

  .intro {
    width: 85%;
  }
  .intro .large {
    font-size: 30px;
  }
  .intro .small {
    width: 100%;
  }
  .btn-container {
    margin-top: 2rem;
  }
  .save {
    font-size: 1.1rem;
    margin: 3rem 0 0 2rem;
  }
  .services {
    font-size: 20px;
    text-align: center;
    margin-left: 0;
  }

  .card-section {
    flex-flow: column wrap;
    margin-top: 2rem;
    padding: 1rem 0;
    margin-left: 0;
  }
  .view-more a {
    margin-right: 1.5rem;
  }
}
