.info-section{
    width: 49%;
    display: flex;
    margin-top: 1rem;
    align-items: flex-start;
}
.info-section p{
    font-size: 12px;
    margin-left: 0.5rem;
    margin-bottom: 0;
}
.hosp-logo{
    width: 50%;
    margin-top: 3rem;
}
.logo-cont{
    width: 80%;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: space-between;
}
.logo-cont .logo{
    padding: 1rem;
    border: 2px solid #F79B39;
    border-radius: 50%;
}
.logo-btns{
    margin-top: 2rem;
    text-align: center;
}
.change-logo{
    padding: 12px 40px;
}
.change{
    position: relative;
}
.file{
    /* visibility: hidden; */
    position: absolute;
    /* top: 0.2rem; */
    /* left: 2rem; */
    z-index: 2;
    /* display: none; */
} 
.logo-btns .delete{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
}
.delete span{
    color: #A038C7;
    font-weight: 600;
}
.delete:hover{
    opacity: 0.7;
}
.hosp-details{
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
}
.details1{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.details2{
    width: 40%;
    display: flex;
    flex-direction: column;
}
.hosp-details label{
    margin-bottom: 0.5rem;
    color: #222222;
    font-weight: 600;
    font-size: 16px;
}
.hosp-details input{
    margin-bottom: 3rem;
    outline: none;
    padding: 18px;
    border-radius: 10px;
    border: 2px solid #F79B39;
    box-sizing: border-box;
    cursor: no-drop;
}