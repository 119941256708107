.nav{
    width: 100%;
    height: 6rem;
    position: sticky;
    top: 0;
    z-index: 1000;
    transition: 0.5s ease-in-out;
    /* background: rgb(178, 147, 182); */
    /* background: #FCDEBD; */
    background-color: #fff;
}
.nav2{
    background-color: #fff;
    box-shadow: 0px 4px 10px -1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
}
.logo{
    width: 15%;
}
.logo img{
    width: 100%;
}
.nav-content{
    width: 87%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 6rem;
}
.nav-links li{
    display: inline;
    margin-left: 2.5rem;
}
.nav-item, .nav-item2{
    color: #F79B39;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    transition: 0.5s ease-in-out;
}
.nav-item:hover, .nav-item2:hover, .active-nav{
    color: #66276D;
}
.person{
    font-size: 20px;
    margin-right: 0.5rem;
}
.arrow{
    margin-left: 0.5rem;
}
#menu{
    display: none;
} 
.mobile{
    display: none;
}
.account{
    cursor: pointer;
}
.droplink{
    color: #F79B39 !important;
    font-weight: 600 !important;
    cursor: pointer !important;
    text-decoration: none !important;
}
.add-patient-btn{
    background-color: #F79B39 !important;

}
.add-patient-btn:hover{
    background-color: #66276D !important;
}


@media screen and (max-width: 1000px){
    .nav{
        background-color: #fff;
        box-shadow: 0px 4px 10px -1px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(10px);
    }
    .logo{
        width: 150px;
    }
    .nav-links{
        width: 100%;
        background-color: white;
        position: absolute;
        top: 5.9rem;
        left: 0;
        box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.25);
        padding-bottom: 2rem;
    }
    .nav-links li{
        display: block;
        list-style: none;
        margin-left: 2rem;
        margin-top: 2rem;
    }
    .desktop{
        display: none;
    }
    #menu{
        display: block;
        color: #66276D;
        font-weight: 600;
        font-size: 1.3rem;
        font-style: italic;
    }
}