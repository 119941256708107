.how-it-works{
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.how-it-works .left, .how-it-works .right{
    width: 48%;
}
.how-it-works .group1{
    width: 100%;
    height: 50vh;
    background: url(../../../assets/images/circles.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;  
    justify-content: center;
    margin-bottom: 2rem;
    text-align: center;
}
.group-text{
    width: 80%;
    margin: auto;
}
.group-text h1{
    color: #000;
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 0.8rem;
}
.group-text h3{
    color: #222;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}
.group2 h3{
    color: #F79B39;
    margin-bottom: 0.5rem;
}
.group2 p{
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
}
.how-it-works .right img{
    width: 100%;
}


/* FAQ */
.faq{
    margin-bottom: 5rem;
}
.faq-box{
    margin-bottom: 1.5rem;
    border: 2px solid #66276D;
    /* background: #FCDEBD !important; */
    border-radius: 10px;
    cursor: pointer;
}
.faq-box.active-box .arrow2{
    transform: rotate(180deg);
}
.arrow2{
    background: #F3F7D4;
    color: #66276D;
}
.faq p{
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
}


.how h3{
    margin-top: 1rem;
}
.round-icon{
    color: #66276D;
    font-size: 35px;
    padding: 10px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: -1.2rem;
    left: 45%;
}



@media screen and (max-width:1000px) {
    .how-it-works{
        flex-direction: column;
        gap: 1rem;
        width: 85%;
        margin-top: 2rem;
    }
    .how-it-works .left, .how-it-works .right{
        width: 100%;
    }
    .how-it-works .group1{
        width: 100%;
        height: 40vh;
    }
    
    
}