.signup{
    background-image: url(../../../assets/images/Rectangle\ 70.png);
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
}

.signup .left{
    width: 45%;
    height: auto;
    position: static;
    margin: 2rem 0;
}
.signup .left .logo{
    margin-bottom: 2.5rem;
}
.signup-h4{
    color: #fff;
    text-align: center;
    font-size: 20px;
}
.notice-cont{
    display: flex;
    align-items: center;
    margin: 1rem 0;
}
.notice-text{
    font-size: 13px;
    color: #222;
    margin: 0;
    margin-left: 0.5rem;
    text-align: center;
}
.linked-btns{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0 1rem 0;
}
.link-btn{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: none;
    background: #F5EBF9;
    color: #66276D;
    font-weight: 600;
    cursor: pointer;
}
.active{
    background-color: #66276D;
    color: #F5EBF9;
}
.line{
    width: 25%;
    height: 2px;
    background-color: #fff;
}
.line.active{
    background-color: #66276D;
}
.title{
    color: #fff;
    text-align: center;
    margin-bottom: 0.5rem;
}
.instruction{
    color: #222;
    text-align: center;
    margin-bottom: 1rem;
}
.signup-form input{
    margin: 0.8rem 0;
}
.signup .continue-btn{
    margin-top: -0.5rem;
    margin-bottom: 1rem;
}
.signup .right img{
    height: auto;
}


@media screen and (max-width:1000px){
    .signup .left{
        margin: 0;
        width: 100%;
    }    
    .notice-cont{
        align-items: flex-start;
    }
    .notice-text{
        margin-left: 0.1rem;
    }
    .line{
        width: 31%;
    }
    .signup-cont{
        padding-bottom: 2rem;
    }
}
