.modal, .alert{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center; 
}
.modal-box{
    width: 35%;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 2rem 0 0 0;
}
.modal-box1{
    padding-bottom: 2rem;
}
.modal h3{
    color: #F79B39;
}
.contribution-form{
    width: 70%;
    margin: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 2rem;
}
.contribution-form input, select{
    padding: 15px;
    border: 2px solid #F79B39;
    margin-bottom: 1.5rem;
    outline: none;
}
.same-width{
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
}
.select-location{
    width: 70%;
    border-radius: 10px;
}
.money{
    width: 100%;
    display: flex;
    justify-content: left;
}
.money select{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.money input{
    width: 40%;
    border: 2px solid #F79B39;
    border-left: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    outline: none;
}
.anonymous{
    display: flex;
    align-items: flex-start;
}
.anonymous p{
    font-size: 12px;
    margin-left: 0.5rem;
}
.form-submit1{
    width: 100%;
}
.close{
    color: gray;
    padding: 5px;
    position: absolute;
    top: 1rem;
    right: 2rem;
    border: 1px solid #F79B39;
    cursor: pointer;
}


/* CONTRIBUTORS MODAL */
.cont-box{
    text-align: unset;
    height: 400px;
    position: relative;
    overflow: auto;
}
.cont-content{
    width: 80% !important;
}
.contr{
    display: flex;
    justify-content: space-between;
    margin-bottom: -2.5rem;
}
.cont-h3{
    text-align: center;
    margin-bottom: 0.5rem;
}
.cont-cancel{
    position: absolute;
    bottom: 2rem;
}
.notice-icon{
    width: 15px;
    margin-left: 0.5rem;
    cursor: pointer;
}







@media screen and (max-width:1000px){
    .modal-box{
        width: 90%;
    }
    .contribution-form{
        width: 80%;
    }
    
}