.prod-details{
    display: flex;
}
.prod-left{
    width: 40%;
}
.prod-right{
    width: 60%;
    margin-bottom: 3rem;
}
.prod-right h4{
    width: 80%;
    margin: auto;
    margin-bottom: 1rem;

}
.prod-right2{
    display: flex;
    flex-flow: column wrap;
}
article{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}
.prod-card{
    width: 300px;
    /* margin: 2rem; */
    /* height: fit-content; */
}


@media screen and (max-width:1000px) {
    .prod-details{
        flex-direction: column;
    }
    .prod-left{
        width: 100%;
    }
    .prod-right{
        width: 100%;
    }
    .bar3{
        margin: auto;
    }
    .prod-right h4{
        text-align: center;
    }
    article{
        flex-direction: column;
        justify-content: center;
    }
    .prod-card{
        width: 90%;
    }
    
    
}